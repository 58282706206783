export const availableTimeSlots = [
  {
    id: 0,
    name: "Morning Schedule",
    time: [
      {
        startTime: "06:00am",
        endTime: "06:15am",
      },
      {
        startTime: "06:20am",
        endTime: "06:35am",
      },
      {
        startTime: "06:40am",
        endTime: "06:55am",
      },
      {
        startTime: "07:00am",
        endTime: "07:15am",
      },
      {
        startTime: "07:20am",
        endTime: "07:35am",
      },
      {
        startTime: "07:40am",
        endTime: "07:55am",
      },
      {
        startTime: "08:00am",
        endTime: "08:15am",
      },
      {
        startTime: "08:20am",
        endTime: "08:35am",
      },
      {
        startTime: "08:40am",
        endTime: "08:55am",
      },
      {
        startTime: "09:00am",
        endTime: "09:15am",
      },
      {
        startTime: "09:20am",
        endTime: "09:35am",
      },
      {
        startTime: "09:40am",
        endTime: "09:55am",
      },
      {
        startTime: "10:00am",
        endTime: "10:15am",
      },
      {
        startTime: "10:20am",
        endTime: "10:35am",
      },
      {
        startTime: "10:40am",
        endTime: "10:55am",
      },
      {
        startTime: "11:00am",
        endTime: "11:15am",
      },
      {
        startTime: "11:20am",
        endTime: "11:35am",
      },
      {
        startTime: "11:40am",
        endTime: "11:55am",
      },
    ],
  },

  {
    id: 1,
    name: "Afternoon Schedule",
    time: [
      {
        startTime: "12:00pm",
        endTime: "12:15pm",
      },
      {
        startTime: "12:20pm",
        endTime: "12:35pm",
      },
      {
        startTime: "12:40pm",
        endTime: "12:55pm",
      },
      {
        startTime: "01:00pm",
        endTime: "01:15pm",
      },
      {
        startTime: "01:20pm",
        endTime: "01:35pm",
      },
      {
        startTime: "01:40pm",
        endTime: "01:55pm",
      },
      {
        startTime: "02:00pm",
        endTime: "02:15pm",
      },
      {
        startTime: "02:20pm",
        endTime: "02:35pm",
      },
      {
        startTime: "02:40pm",
        endTime: "02:55pm",
      },
      {
        startTime: "03:00pm",
        endTime: "03:15pm",
      },
      {
        startTime: "03:20pm",
        endTime: "03:35pm",
      },
      {
        startTime: "03:40pm",
        endTime: "03:55pm",
      },
      {
        startTime: "04:00pm",
        endTime: "04:15pm",
      },
      {
        startTime: "04:20pm",
        endTime: "04:35pm",
      },
      {
        startTime: "04:40pm",
        endTime: "04:55pm",
      },
      {
        startTime: "05:00pm",
        endTime: "05:15pm",
      },
    ],
  },

  {
    id: 2,
    name: "Evening Schedule",
    time: [
      {
        startTime: "05:20pm",
        endTime: "05:35pm",
      },
      {
        startTime: "05:40pm",
        endTime: "05:55pm",
      },
      {
        startTime: "06:00pm",
        endTime: "06:15pm",
      },
      {
        startTime: "06:20pm",
        endTime: "05:35pm",
      },
      {
        startTime: "06:40pm",
        endTime: "06:55pm",
      },
      {
        startTime: "07:00pm",
        endTime: "07:15pm",
      },
      {
        startTime: "07:20pm",
        endTime: "07:35pm",
      },
      {
        startTime: "07:40pm",
        endTime: "07:55pm",
      },
      {
        startTime: "08:00pm",
        endTime: "08:15pm",
      },
      {
        startTime: "08:20pm",
        endTime: "08:35pm",
      },
      {
        startTime: "08:40pm",
        endTime: "08:55pm",
      },
      {
        startTime: "09:00pm",
        endTime: "09:15pm",
      },
      {
        startTime: "09:20pm",
        endTime: "09:35pm",
      },
      {
        startTime: "09:40pm",
        endTime: "09:55pm",
      },
      {
        startTime: "10:00pm",
        endTime: "10:15pm",
      },
      {
        startTime: "10:20pm",
        endTime: "10:35pm",
      },
      {
        startTime: "10:40pm",
        endTime: "10:55pm",
      },
      {
        startTime: "11:00pm",
        endTime: "11:15pm",
      },
      {
        startTime: "11:20pm",
        endTime: "11:35pm",
      },
      {
        startTime: "11:40pm",
        endTime: "11:55pm",
      },
      // {
      //   startTime: "12:00am",
      //   endTime: "12:15am",
      // },
    ],
  },
];
import { useEffect } from "react";
import Axios, { AxiosError } from "axios";
import { useAppStore } from "../store";
import { auth } from "./firebase.config";
import { notification } from "antd";
import { NotificationType } from "../utils";

type Props = {
  children: any;
};

export const base = "https://api-v2.medpharma.care";
export const stage = "https://www.api-v2-staging.medpharma.care";
export const tip = "http://localhost:9090";

const instance = Axios.create({
  baseURL: base,
  headers: { "Content-Type": "application/json" },
});

const AxiosInterceptor = ({ children }: Props) => {
  const [api, contextHolder] = notification.useNotification();
  const { logOut, rehydrated } = useAppStore();

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    message: string
  ) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  useEffect(() => {
    instance.interceptors.request.use(
      async function (config) {
        const user = auth.currentUser;
        if (user) {
          const token = await user.getIdToken();
          config.headers["firebase-token"] = token;
        }
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
    instance.interceptors.response.use(
      (res) => {
        return res;
      },
      async (error: AxiosError) => {
        if (
          (error.response?.status === 401 || error.response?.status === 403) &&
          rehydrated
        ) {
          openNotificationWithIcon(
            "warning",
            "Warning",
            "Session expired! Kindly signin..."
          );
          return logOut();
        }
        return Promise.reject(error);
      }
    );

    instance.defaults.headers.get["Content-Type"] = "application/json";
    instance.defaults.headers.post["Content-Type"] = "application/json";
    instance.defaults.headers.patch["Content-Type"] = "application/json";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logOut]);

  return (
    <>
      {children}
      {contextHolder}
    </>
  );
};

export default instance;
export { AxiosInterceptor };
